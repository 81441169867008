import React, {useState, useContext, useEffect} from "react";
import {FORM_ERROR} from "final-form";

import {createReservation} from "../../../lib/reservation-service";
import {
  IdentifyReservationPayment,
  IdentifyReservationSuccess,
  IdentifyPhoneNumber,
  trackNextButtonClick,
  trackConfirmedScreenPage,
  trackConfirmAndPayClicked,
  trackProductViewed,
} from "../../../lib/analytics";
import PaymentReservationContext from "../../context/paymentReservation";
import ButterCMSContentContext from "../../context/butterCMSContent";
import useUTMData from "../../context/utmData";

import classes from "./ReservationSubmitionForm.module.scss";
import ConfirmForm from "./ConfirmForm";
import WelcomeMessage from "./WelcomeMessage";
import classNames from "classnames";
import RefundableModal from "../../Shared/StartPaymentModal/RefundableModal";
import {useTerms} from "../../ReservationForm/useTerms";
import ActivationFlowModal from "../../Shared/ActivationFlowModal/ActivationFlowModal";

const PAGE_STATES = {
  CONFIRM_FORM: 0,
  WELCOME_PAGE: 1,
};

const ReservationSubmitionForm = ({
  className,
  onNextBtnClick,
  startFee: propsStartFee,
  monthlyPayment: propsMonthlyFee,
  priceTermMonths,
  onHide,
}) => {
  const {
    state: {color: activeColor, monthlyPayment, startFee},
  } = useContext(PaymentReservationContext);

  const [showRefundableModal, setShowRefundableModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pageState, setPageState] = useState(PAGE_STATES.CONFIRM_FORM);
  const [reservationCode, setReservationCode] = useState("");
  const [customerId, setCustomerId] = useState("");
  const getUTMData = useUTMData();
  const {
    data: {tcModalContent, refundableModalContent, legalContent},
  } = useTerms();

  useEffect(() => {
    if (phoneNumber && typeof onNextBtnClick === "function") {
      onNextBtnClick();
    }
  }, [phoneNumber]);

  const {refundable_legal, refundable_title} =
    legalContent?.fields?.body?.[0].fields;

  const onConfirmSubmit = async ({
    firstName,
    lastName,
    mobileNumber,
    email,
    expDate,
    cardNumber,
    cvc,
    zip,
    cardHolderName,
  }) => {
    const data = {
      firstName,
      lastName,
      email,
      cardNumber,
      cardExp: expDate,
      cardCVV: cvc,
      cardZip: zip,
      mobileNumber: mobileNumber,
      cardHolderName,
      startFee: propsStartFee,
      monthlyFee: propsMonthlyFee,
      priceTermMonths,
      model3Options: {
        color: activeColor,
        deposit: 500,
        monthly: propsMonthlyFee,
        startFee: propsStartFee,
        reservationFee: 100,
      },
    };

    IdentifyReservationPayment({
      firstName,
      lastName,
      phoneNumber: phoneNumber,
      zipcode: zip,
      email,
    });
    trackConfirmAndPayClicked();
    try {
      const res = await createReservation(data);
      if (res) {
        try {
          const {customerId, reservationNumber} = res.toObject();
          setCustomerId(customerId);
          trackConfirmedScreenPage();
          setReservationCode(reservationNumber);
          IdentifyReservationSuccess({
            userId: customerId,
            email,
          });
        } catch (e) {
          console.error("error attaching analytics data.", e);
        }

        setPageState(PAGE_STATES.WELCOME_PAGE);
      }
    } catch (e) {
      // console.log("err", e);
      return {
        [FORM_ERROR]:
          "Your credit card could not be charged. Please try again, or add another card.",
      };
    }
  };

  const onShowRefundableModal = () => setShowRefundableModal(true);

  return (
    <div className={classNames(className, classes.getStartedWrapper)}>
      {pageState === PAGE_STATES.CONFIRM_FORM ? (
        <>
          <div className="container-fluid use-default pb-2 gx-3 gx-lg-4">
            <div>
              <h5 className={classes.formTitle}>Start your reservation</h5>
            </div>
            <div className={classes.boldText}>
              <a
                className={classes.fullyRefundableLink}
                onClick={onShowRefundableModal}
              >
                Place a fully-refundable $100 reservation deposit to secure your
                spot. {refundable_title}
              </a>
            </div>
          </div>
          <ConfirmForm
            onSubmit={onConfirmSubmit}
            tcModalContent={tcModalContent}
          />
        </>
      ) : (
        <WelcomeMessage
          phoneNumber={phoneNumber}
          reservationCode={reservationCode}
          customerId={customerId}
          onHide={onHide}
        />
      )}
      <RefundableModal
        show={showRefundableModal}
        onHide={() => setShowRefundableModal(false)}
        refundableModalContent={refundableModalContent}
      />
    </div>
  );
};

export default ReservationSubmitionForm;
