import TextField from "../Form/TextField";
import Image from "next/legacy/image";
import s from "./ReservationForm.module.scss";
import cardValidator from "card-validator";
import classNames from "classnames";
import FormLabel from "react-bootstrap/FormLabel";
import Visa from "../../../public/svg/cclogos/visa.svg";
import Amex from "../../../public/svg/cclogos/amex.svg";
import Mastercard from "../../../public/svg/cclogos/mastercard.svg";
import Discover from "../../../public/svg/cclogos/discover.svg";
import Jcb from "../../../public/svg/cclogos/jcb.svg";

const CardNumberForm = (props) => {
  // Set the active card
  const {card} = cardValidator.number(props.input.value);
  const activeCardType = card?.type || "";

  return (
    <div style={{position: "relative"}}>
      <FormLabel>Card number</FormLabel>
      <div className={s.ccLogoWrap}>
        <div className={s.ccInput}>
          <TextField {...props} />
        </div>
        <div className={s.ccLogos}>
          <ul>
            <li className={classNames({[s.faded]: activeCardType !== "visa"})}>
              <div>
                <Image src={Visa} alt="Visa" layout="responsive" />
              </div>
            </li>
            <li
              className={classNames({
                [s.faded]: activeCardType !== "american-express",
              })}
            >
              <div>
                <Image src={Amex} alt="American Express" layout="responsive" />
              </div>
            </li>
            <li
              className={classNames({
                [s.faded]: activeCardType !== "mastercard",
              })}
            >
              <div>
                <Image src={Mastercard} alt="Mastercard" layout="responsive" />
              </div>
            </li>
            <li
              className={classNames({
                [s.faded]: activeCardType !== "discover",
              })}
            >
              <div>
                <Image src={Discover} alt="Discover" layout="responsive" />
              </div>
            </li>
            <li className={classNames({[s.faded]: activeCardType !== "jcb"})}>
              <div>
                <Image src={Jcb} alt="JCB" layout="responsive" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardNumberForm;
